function initHistoryBackButton(){
    const buttons = document.querySelectorAll('.js-history-back');
    if(buttons){
        buttons.forEach(elem=>{
            elem.addEventListener("click", function(){
                history.back();
            });
        })
    }
}

initHistoryBackButton();